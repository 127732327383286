$(document).ready(function () {


    // // * * * * * * * * * * * * * * * * * * * * * * * * *
    // // * teleport links
    // // *
    // // * @set outer parent element class: js-href-teleport-wrapper
    // // * @set link (<a> tag) element class: js-href-teleport-link
    // // * @set element to add the link to class: js-href-teleport
    // // *
    // // * This adds a link tag (<a>) to other elements within a wrapper
    // // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // // *
    // $(".js-href-teleport").each(function () {
    //     var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
    //         href = $link.attr("href"),
    //         target = $link.attr("target") ? $link.attr("target") : '_self';

    //     if (href) {
    //         $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
    //     }
    // });



    // // * * * * * * * * * * * * * * * * * * * * * * * * *
    // // * parent clickable elements (excludes links inside)
    // // *
    // // * @set outer parent element class: js-click-item-parent
    // // * @set link (<a> tag) element class: js-click-item-link
    // // *
    // // * This makes the whole element clickable and still
    // // * makes other links inside clickable with their target
    // // *
    // $(".js-click-item-parent").delegate('a', 'click', function (e) {
    //     var target = $(this).attr("target"),
    //         url = $(this).attr("href");

    //     if (target == "_blank") {
    //         window.open(url);
    //     } else {
    //         window.location = url;
    //     }
    //     return false;
    // }).click(function () {
    //     var target = $(this).find("a.js-click-item-link").attr("target"),
    //         url = $(this).find("a.js-click-item-link").attr("href");

    //     if (target == "_blank") {
    //         window.open(url);
    //     } else {
    //         window.location = url;
    //     }
    //     return false;
    // });




    // // * * * * * * * * * * * * * * * * * * * * * * * * *
    // // * animateIn
    // // *
    // // *
    // var offset = 80; // Distance from Browserbottom & -top where the animation should start

    // function fadeInElements() {
    //     var viewPortStart = $(window).scrollTop(),
    //         viewPortEnd = viewPortStart + $(window).height();


    //     $(".animateIn:visible").each(function () {
    //         var elementTop = $(this).offset().top,
    //             elementBottom = $(this).offset().top + $(this).outerHeight();

    //         if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
    //             var delay = $(this).data("animation-delay");
    //             $(this).css("transition-delay", delay + "s").addClass("animateIn--active");

    //         } else {
    //             $(this).removeClass("animateIn--active");
    //         }
    //     });
    // }

    // $(window).on("scroll",function () {
    //     fadeInElements();
    // });

    // fadeInElements();


    // // * * * * * * * * * * * * * * * * * * * * * * * * *
    // // * add target blank to external links
    // // *
    // // *
    // $('a:not([data-targetblank=ignore])').each(function () {
    //     if (location.hostname === this.hostname || !this.hostname.length) {
    //         // ... do nothing?
    //     } else {
    //         $(this).attr('target', '_blank');
    //     }
    // });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").on("click",function () {
        $(this).toggleClass("active");
        $(".js-nav-mobile").toggleClass("active");
        $("body").toggleClass("freeze");
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * swiper
    // *
    // *
    let swiper;
    let swiperModal;
    let swiperOverview;

    const enableSwiper = function (callback) {
        let autoplayDelay = 21000;

        swiper = new Swiper('.swiper-start', {
            direction: 'horizontal',
            centeredSlides: true,
            speed: 1000,
            noSwiping: true,
            mousewheel: {
                forceToAxis: false,
            },

            autoplay: {
                delay: autoplayDelay,
                disableOnInteraction: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                transitionStart: (swiper) => {
                    $(".js-animate-top").removeClass("animateIn--active");
                    $(".js-animate-scale").removeClass("animateIn--active");
                    $(".swiper-slide-next .slider__image").removeAttr("loading");

                    var overviewButton = $(".js-nav-overview");
                    if (swiper.activeIndex === 0) {
                        overviewButton.removeClass("active");
                    }
                },
                transitionEnd: (swiper) => {
                    $(".js-animate-top").addClass("animateIn--active");
                    $(".js-animate-scale").addClass("animateIn--active");

                    var overviewButton = $(".js-nav-overview");
                    if (swiper.activeIndex !== 0) {
                        overviewButton.addClass("active");
                    }
                    activeSlide = swiper.activeIndex
                },
            },
        });

        setTimeout(() => {
            swiper.autoplay.stop();

        }, autoplayDelay + 1000);


        swiper.slides.length > 0 ? callback() : undefined;
    };

    const enableSwiperModal = function () {
        swiperModal = new Swiper('.swiper-modal', {
            direction: 'horizontal',
            // speed: 1500,
            slidesPerView: 'auto',
            spaceBetween: 35,
            centeredSlides: true,
            initialSlide: 0,
            freeMode: true,
            mousewheel: {
                forceToAxis: false,
            },
            navigation: {
                nextEl: '.swiper-modal-button-next',
                prevEl: '.swiper-modal-button-prev',
            }
        });
    };

    const enableSwiperOverview = function (callback) {

        swiperOverview = new Swiper('.swiper-overview', {
            slidesPerView: 'auto',
            freeMode: true,
            spaceBetween: 30,
            // centeredSlides: true,
            grid: {
                rows: 2,
            },
            mousewheel: {
                forceToAxis: false,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        swiperOverview.slides.length > 0 ? callback() : undefined;
    };

    const slideToHash = function () {
        let data_id = window.location.hash.split('#')[1];
        $modal = $(".js-modal#" + data_id);
        let index = $('[data-modal="' + data_id + '"]').index();

        if ($modal.length === 1) {
            $(".js-modal-close-wrapper").addClass("active");
            $modal.addClass("active");
            swiper.slideTo(index);

            $("body").addClass("freeze");
        }
    };

    const slideToOverviewHash = function () {
        let data_id = window.location.hash.split('#')[1];
        $modal = $(".js-modal#" + data_id);
        let index = $('[data-modal="' + data_id + '"]').index();

        if ($modal.length === 1) {
            $(".js-modal-close-wrapper").addClass("active");
            $modal.addClass("active");
            swiperOverview.slideTo(parseInt(Math.max((index / 2), 0)));
            console.log(parseInt(Math.max((index / 2), 0)));
            console.log(index)

            $("body").addClass("freeze");
        }
    };



    const removeHash = function () {
        var scrollV, scrollH, loc = window.location;
        if ("pushState" in history)
            history.pushState("", document.title, loc.pathname + loc.search);
        else {
            // Prevent scrolling by storing the page's current scroll offset
            scrollV = document.body.scrollTop;
            scrollH = document.body.scrollLeft;

            loc.hash = "";

            // Restore the scroll offset, should be flicker free
            document.body.scrollTop = scrollV;
            document.body.scrollLeft = scrollH;
        }
    }





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * js-modal
    // *
    // *
    $(".js-open-modal").on("click", function () {
        var data = $(this).attr("data-modal");
        window.location.hash = data;
        $modal = $(".js-modal" + "#" + data);
        if ($modal.length === 1) {
            $(".js-modal-close-wrapper").addClass("active");
            $modal.addClass("active");
            $("body").addClass("freeze");
        }
    })


    $(".js-modal-close").on("click", function () {
        $(".js-modal").removeClass("active");
        $(".js-modal-close-wrapper").removeClass("active");
        $("body").removeClass("freeze");
        removeHash();
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * js-modal-pages
    // *
    // *
    $(".js-open-about").on("click", function () {
        $(".js-modal-about").addClass("active");
        $("body").addClass("freeze");
    })

    $(".js-open-contact").on("click", function () {
        $(".js-modal-contact").addClass("active");
        $("body").addClass("freeze");
    })
    $(".js-close-modal-page").on("click", function () {
        $(".js-modal-about").removeClass("active");
        $(".js-modal-contact").removeClass("active");
        $("body").removeClass("freeze");
    })

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * swiper activation
    // *
    // *
    const breakpoint = window.matchMedia('(max-width:768px)');
    const breakpointChecker = function () {
        if (breakpoint.matches === true) {
            $(".js-animate-scale").removeClass("animateIn--active animateIn--scale");
            $(".js-animate-top").removeClass("animateIn animateIn--active animateIn--toTop ");

            if (swiper !== undefined && swiper.slides.length > 0) {
                if (swiper.slides.length > 0) {
                    swiper.destroy(true, true);
                }

            }
            if (swiperOverview !== undefined && swiperOverview.slides.length > 0) {
                swiperOverview.destroy(true, true);
            }
            if (swiperModal !== undefined) {
                swiperModal.forEach(element => {
                    element.destroy(true, true);
                })
            }
            return;
        } else if (breakpoint.matches === false) {
            $(".js-animate-scale").addClass("animateIn--scale");
            $(".js-animate-top").addClass("animateIn animateIn--toTop");

            enableSwiperModal();
            enableSwiper(slideToHash);
            enableSwiperOverview(slideToOverviewHash);
            return;
        }
    };

    breakpoint.addListener(breakpointChecker);
    breakpointChecker();
});








// * * * * * * * * * * * * * * * * * * * * * * * * *
// * on Load
// *
// *
$(window).on("load ", function () {
    let hash = window.location.hash;
    $modal = $(".js-modal" + hash);

    if ($modal.length === 1) {
        $(".js-modal-close-wrapper").addClass("active");
        $modal.addClass("active");
        $("body").addClass("freeze");
    }

    if (hash && window.innerWidth < 768) {
        $elem = $('[data-modal="' + hash.split('#')[1] + '"]');
        $('html, body').animate({
            scrollTop: $elem.offset().top
        }, 'fast');
    }

});
